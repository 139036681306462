import * as Sentry from "@sentry/nextjs";

export const forgotPasswordApi = async (credentials) => {
    const url = `${process.env.BACKEND_URL}core/password-forget/`;
    try {
        const response = await fetch(url, {
            body: JSON.stringify(credentials),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response?.json();
        return data;
    } catch (error) {
        Sentry.captureException(error);
        return error?.response?.data;
    }
};
