"use client";

import React from "react";
import { HEADER_LINK_CLASS } from "./constants";
import Image from "next/image";
import GTMLink from "../../GTMLink";
import { GoogleTagManagerEvents } from "../../../../common";

const HeaderLinkWithIcon = ({
    text,
    link,
    width,
    className,
    src,
    margin,
    onClick,
    endEndorment,
    startEndorment,
}) => {
    return (
        <GTMLink
            event={GoogleTagManagerEvents.navigationInteractions}
            place={"header"}
            menuName={"header navigation menu"}
            href={link}
            passHref
            onClick={onClick}
            prefetch
        >
            <div className={width + HEADER_LINK_CLASS + className}>
                {startEndorment && (
                    <Image
                        width={25}
                        height={25}
                        loading="lazy"
                        src={src}
                        alt={"Header Icon"}
                    />
                )}

                <span className={margin}>{text}</span>
                {endEndorment && (
                    <Image
                        width={25}
                        height={25}
                        loading="lazy"
                        src={src}
                        alt={"Header Icon"}
                    />
                )}
            </div>
        </GTMLink>
    );
};

export default HeaderLinkWithIcon;
