"use client";
import React from "react";
import { ToastContainer } from "react-toastify";
import { contextClass, contextIcons, TOAST_TIME } from "./constants";
import "react-toastify/dist/ReactToastify.css";
import { BODY_CLASS, BOTTOM_CENTER, TOAST_CONTAINER_CLASS } from "./styles";
import { DEFAULT } from "../../../public/Constants/EnumConstants";
import Image from "next/image";

const CustomToastContainer = () => {
    return (
        <ToastContainer
            toastClassName={({ type }) =>
                contextClass[type] + TOAST_CONTAINER_CLASS
            }
            position={BOTTOM_CENTER}
            icon={({ type, icon }) => (
                <Image
                    alt={"Toast icon"}
                    width={40}
                    height={40}
                    src={type === DEFAULT ? icon : contextIcons[type]}
                />
            )}
            bodyClassName={() => BODY_CLASS}
            closeButton={false}
            autoClose={TOAST_TIME}
            hideProgressBar
        />
    );
};

export default CustomToastContainer;
