import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { changeCurrency } from "../lib/Country/action";
const initialState = {
    userCountry: null,
};

const countrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        updateCountry: (state, { payload }) => {
            state.userCountry = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, action) => {
            return {
                ...state,
                ...action?.payload?.country,
            };
        });
    },
});

export const { updateCountry } = countrySlice.actions;
export default countrySlice.reducer;
