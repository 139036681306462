"use client";
import React from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { SessionProvider } from "next-auth/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { GoogleTagManager } from "@next/third-parties/google";
import { usePathname } from "next/navigation";
import { PrismicPreview } from "@prismicio/next";
import { PrismicProvider } from "@prismicio/react";
import { createWrapper } from "next-redux-wrapper";
import { GoogleAnalytics } from "nextjs-google-analytics";
import store from "../store/store";
import { linkResolver, repositoryName } from "../prismicio";
import Loading from "./loading";
const Whatsapp = dynamic(() => import("../components/Atoms/WhatsApp"));

export default function ProviderWrapper({ children, props }) {
    const persistor = persistStore(store);
    const pathName = usePathname();
    return (
        <SessionProvider>
            <Provider store={store}>
                <PersistGate loading={<Loading />} persistor={persistor}>
                    <PrismicProvider
                        linkResolver={linkResolver}
                        internalLinkComponent={({ href, ...props }) => (
                            <Link href={href} {...props}></Link>
                        )}
                    >
                        {children}
                    </PrismicProvider>
                    {!pathName.includes("survey") && <Whatsapp />}
                </PersistGate>
            </Provider>
        </SessionProvider>
    );
}
