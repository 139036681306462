"use client";
import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useDispatch, useSelector } from "react-redux";
import { usePathname } from "next/navigation";
import { fetchUserData } from "../store/userSlice";
import Transition from "./Transition";
import { getCookie, setCookie } from "cookies-next";
import { resetAllSlices } from "../store/combinedResetActions";
import PromoBanner from "../components/Molecules/PromoBanner";
import NewFooterSection from "../components/Molecules/NewFooterSection";
import Loading from "./loading";
import { createWrapper } from "next-redux-wrapper";
import NewHeaderSection from "../components/Molecules/NewHeaderSection";
import {
    SAUDI_ISO,
    DUBAI_ISO,
    PROMO_BANNER,
    FOOTER,
    AUTHENTICATED,
    UNAUTHENTICATED,
    HOTJAR_JS_PATH,
    PROMO_RESTRICTED_ROUTES,
    FOOTER_RESTRICTED_ROUTES,
} from "../public/Constants/EnumConstants";

const App = ({ children }) => {
    const { status } = useSession();
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state?.user);
    const { userCountry } = useSelector((state) => state?.country);
    const pathName = usePathname();

    useEffect(() => {
        if (status === AUTHENTICATED) {
            dispatch(fetchUserData());
        } else if (status === UNAUTHENTICATED) {
            userData !== null && dispatch(resetAllSlices());
        }
    }, [status]);

    useEffect(() => {
        if (pathName === "/" && userCountry?.flag === SAUDI_ISO) {
            window.location.href = process.env.HUBSPOT_KSA_WEB_URL;
        }
    }, [userCountry]);

    const isVisible = (section) => {
        if (section === PROMO_BANNER) {
            return !PROMO_RESTRICTED_ROUTES?.find((route) =>
                pathName?.includes(route)
            );
        } else if (section === FOOTER) {
            return !FOOTER_RESTRICTED_ROUTES?.find((route) =>
                pathName?.includes(route)
            );
        }
        return true;
    };

    return (
        <Suspense>
            <NewHeaderSection
                homeUrl={
                    userCountry?.flag === SAUDI_ISO
                        ? process.env.HUBSPOT_KSA_WEB_URL
                        : "/"
                }
            />
            {isVisible(PROMO_BANNER) && <PromoBanner />}
            <Transition>
                {" "}
                <Suspense>{children}</Suspense>{" "}
            </Transition>

            {isVisible(FOOTER) && <NewFooterSection homeUrl={"/"} />}
        </Suspense>
    );
};

const makeStore = () => store;
const wrapper = createWrapper(makeStore);
wrapper.withRedux(App);

export default App;
