import React from "react";
import GTMLink from "../../GTMLink";
import { GoogleTagManagerEvents } from "../../../../common";

const HeaderLink = ({ text, link, external, headerId }) => {
    return (
        <GTMLink
            href={link}
            prefetch={!external}
            replace={!external}
            aria-current="page"
            id={headerId}
            data-cy={headerId}
            text={text}
            external={external}
            event={GoogleTagManagerEvents.navigationInteractions}
            place="header"
            menuName={link}
        >
            {text}
        </GTMLink>
    );
};

export default HeaderLink;
