"use client";
import React from "react";
import TertiaryButton from "../../Atoms/Buttons/TertiaryButton";
import { useSelector } from "react-redux";
import {
    BUTTON_CLASS,
    HIGHLIGHT_TEXT_CLASS,
    PROMO_BANNER_CONTAINER,
    PROMO_TEXT_CONTAINER,
    ENGLISH_TEXT,
    ARABIC_TEXT,
    PROMO_BUTTON_ID_TEXT,
    Montserrat_13PX_NORMAL,
} from "./constants";
import {
    ARABIC,
    ENGLISH,
    REFFERAL_CANDY_URL,
} from "../../../public/Constants/EnumConstants";
import PromoBannerButton from "../../Atoms/Buttons/PromoBannerButton/PromoBannerButton";

const PromoBanner = ({ language }) => {
    const { userCountry } = useSelector((state) => state?.country);
    const promoText = language === ARABIC ? ARABIC_TEXT : ENGLISH_TEXT;
    return (
        <header className={"bg-New_Primary_Default text-center py-2.5 md:py-4"}>
            <div className={PROMO_TEXT_CONTAINER}>
                <div className={Montserrat_13PX_NORMAL}>
                    {language === ARABIC && (
                        <span>
                            <PromoBannerButton
                                text={promoText?.BUTTON_TEXT}
                                simpleText
                                href={REFFERAL_CANDY_URL}
                                className={BUTTON_CLASS}
                                buttonId={"promo-banner-survey-btn-id"}
                            />
                        </span>
                    )}
                    {promoText?.PROMO_TEXT}
                    <span className={HIGHLIGHT_TEXT_CLASS}>
                        {promoText?.UNLIMITED}
                    </span>
                    {promoText?.PROMO_TEXT_2}
                    <span className={HIGHLIGHT_TEXT_CLASS}>
                        {promoText?.ONE_OFF}
                    </span>
                    {` ${userCountry?.price} ${userCountry?.currency}`}
                    {language === ENGLISH && (
                        <span>
                            <TertiaryButton
                                text={promoText?.BUTTON_TEXT}
                                simpleText
                                href={REFFERAL_CANDY_URL}
                                className={BUTTON_CLASS}
                                buttonId={PROMO_BUTTON_ID_TEXT}
                            />
                        </span>
                    )}
                </div>
            </div>
        </header>
    );
};

export default PromoBanner;
