import React from "react";
import { PRIMARY_BUTTON_CLASS } from "./constants";
import GTMLink from "../../GTMLink";
import { GoogleTagManagerEvents } from "../../../../common";

const NewHeaderLoginButton = ({
    text,
    onClick,
    processing,
    width,
    href,
    className,
    style,
    type,
    headerId,
}) => {
    return href ? (
        <GTMLink
            text={text}
            href={href}
            className={width + PRIMARY_BUTTON_CLASS + className}
            style={style}
            onClick={onClick}
            id={headerId}
            data-cy={headerId}
            prefetch
            event={GoogleTagManagerEvents.navigationInteractions}
            place={"header"}
            menuName={"header navigation menu"}
        >
            {text}
        </GTMLink>
    ) : (
        <button
            type={type}
            className={width + PRIMARY_BUTTON_CLASS + className}
            style={style}
            onClick={onClick}
            disabled={processing}
            data-cy={headerId}
        >
            {text}
        </button>
    );
};

export default NewHeaderLoginButton;
